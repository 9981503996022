/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { NextPage } from 'next';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AdvertisingBanner } from '@havenengineering/module-owners-shared-library/dist/components/AdvertisingBanner';
import { Banner } from '@havenengineering/module-owners-shared-library/dist/components/Banner';
import { Button } from '@havenengineering/module-owners-shared-library/dist/components/Button';
import { Card } from '@havenengineering/module-owners-shared-library/dist/components/Card';
import { SectionHeader } from '@havenengineering/module-owners-shared-library/dist/components/SectionHeader';
import { useAuthContext } from '@havenengineering/module-owners-shared-library/dist/contexts/auth';
import clsx from 'clsx';
import useSWR, { useSWRConfig } from 'swr';
import useSWRImmutable from 'swr/immutable';

import { UpComingEvents } from '../components/Activities/UpComingEvents';
import ImportantReads from '../components/Article/ImportantReads/ImportantReads';
import BannerList from '../components/BannerList';
import { FullScreenManagedLettingAdvert } from '../components/LettingMarketing/FullScreenManagedLettingAdvert';
import { LettingAdvertisementDialog } from '../components/LettingMarketing/LettingAdvertisementDialog';
import OwnersGuideBanner from '../components/OwnersGuideBanner/OwnersGuideBanner';
import ParkOpenCounter from '../components/ParkOpenCounter/ParkOpenCounter';
import { EarningPotential } from '../components/Pricing/EarningPotential';
import { SeasonalBannerAnimations } from '../components/SeasonalBannerAnimations';
import { TooltipFactory } from '../components/TooltipFactory/TooltipFactory';
import { LettingsContext } from '../contexts/lettings';
import { fromatBookedDates } from '../helpers/draindown';
// To enable when Drain Down will be necessary
// import { isParkValidForFrostWarning } from '../helpers/draindown';
import {
  fetchWrapper,
  swrFetcher,
  swrLocalFetcher,
  withApiBaseUrl,
  withLettingsApiBaseUrl,
} from '../helpers/fetch';
import { handleGTMBannerImpression, pageView } from '../helpers/googleTag';
import {
  getLettingApplicationsByAccountNo,
  isParkValidForLettingMarketing,
  parseRestrictedParks,
} from '../helpers/lettingMarketing';
import { useIsMounted } from '../hooks/useIsMounted';
import { useLettingAdverts } from '../hooks/useLettingAdverts';
import { yourHolidayHomeTooltipRef } from '../layouts/MainLayout';
import styles from '../styles/pages/homepage.module.scss';
import { LettingConfigEntry } from '../types/lettingConfig';
import {
  ApplicationStatus,
  LettingAdvertForBanner,
  LettingAdvertisement,
  LettingApplicationFields,
} from '../types/lettings';
import { TooltipType } from '../types/onboardingTooltip';
import { Park } from '../types/parks';

const {
  publicRuntimeConfig: {
    PUBLIC_LOGIN_URL,
    PUBLIC_APP_URL,
    PUBLIC_ENTERTAINMENT_APP_URL,
    PUBLIC_ACTIVITIES_APP_URL,
  },
} = getConfig();

const currentlySupportedHeroSeasons = [
  'Winter',
  'Autumn',
  'Xmas',
  'Spring',
  'Easter',
  'Summer',
];

const HomePage: NextPage = () => {
  const { mutate } = useSWRConfig();
  const router = useRouter();
  const isMounted = useIsMounted();

  const { loggedInUser, activeAccount, gtmPageViewAuthInfo } = useAuthContext();

  const isUserLoggedIn = !!loggedInUser;

  const [lettingAppInProgress, setLettingAppInProgress] = useState<
    LettingApplicationFields | undefined
  >();

  const [lettingAdvert, setLettingAdvert] =
    useState<LettingAdvertForBanner | null>(null);

  const [lettingAdvertisement, setLettingAdvertisement] =
    useState<LettingAdvertisement | null>(null);
  const [
    showFullScreenManagedLettingAdvert,
    setShowFullScreenManagedLettingAdvert,
  ] = useState<boolean>(true);

  const [lettingConfig, setLettingConfig] = useState<
    LettingConfigEntry[] | null
  >(null);

  const { lettingSummary } = useContext(LettingsContext);

  const { data: banners } = useSWRImmutable<Banner[]>(
    activeAccount?.parkCode && activeAccount?.vanYear
      ? `/contentful/banners?parkCode=${activeAccount.parkCode}&vanYear=${activeAccount.vanYear}&displayOn=homepage`
      : null,
    swrLocalFetcher
  );

  const fullScreenManagedLettingAdvertSWRKey = `/contentful/full-screen-advert?accountNumber=${activeAccount?.accountNo}&parkCode=${activeAccount?.parkCode}&acceptedToLet=${activeAccount?.acceptedForLet}`;
  const { data: fullScreenManagedLettingAdvert } =
    useSWRImmutable<FullScreenManagedLettingAdvert>(
      activeAccount?.parkCode && activeAccount?.vanYear
        ? fullScreenManagedLettingAdvertSWRKey
        : null,
      swrLocalFetcher
    );

  const { data: homepageWelcomeBanner } =
    useSWRImmutable<HomepageWelcomeBannerData>(
      activeAccount?.parkCode &&
        `/contentful/homepage-welcome-banner?parkCode=${activeAccount?.parkCode.toUpperCase()}`,
      swrLocalFetcher
    );

  const { data: currentDrainDownBookings } = useSWR<DrainDownBooking>(
    activeAccount &&
      `/draindown/actual-bookings/${activeAccount?.accountNo.toUpperCase()}`,
    swrFetcher
  );

  const topPrioBanners = useMemo(
    () => (banners || []).filter((banner) => banner.data.priority === 'top'),
    [banners]
  );

  const normalBanners = useMemo(
    () => (banners || []).filter((banner) => banner.data.priority === 'normal'),
    [banners]
  );

  const park = useSWRImmutable<Park>(
    activeAccount?.parkCode && `/parks/${activeAccount.parkCode}`,
    swrFetcher
  ).data;

  const isLet2OwnLettingOwner = useMemo(
    () => lettingSummary?.let2Own,
    [lettingSummary]
  );

  const { actionRequired: lettingActionRequired, formattedAd: lettingBanner } =
    useLettingAdverts(activeAccount?.accountID, {
      getActionRequired: true,
      getFormattedAd: true,
    });

  useEffect(() => {
    if (!isUserLoggedIn && router.query.letting !== undefined) {
      window.location.assign(
        `${PUBLIC_LOGIN_URL}?redirect=${PUBLIC_APP_URL}%3Fletting`
      );
    }
  }, [router, isUserLoggedIn]);

  useEffect(() => {
    if (lettingBanner && lettingAdvert !== lettingBanner) {
      setLettingAdvert(lettingBanner);
    }
  }, [lettingBanner, lettingAdvert]);

  useEffect(() => {
    if (activeAccount) {
      fetchLettingApplications();
      fetchTargetedLettingCampaign();
      fetchLettingConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAccount, isMounted]);

  useEffect(() => {
    pageView(
      'dashboard',
      '/ga-virtual/owners-account/dashboard',
      'owners account',
      gtmPageViewAuthInfo
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLettingApplications = async () => {
    if (activeAccount && !activeAccount.acceptedForLet) {
      const applications = await getLettingApplicationsByAccountNo(
        activeAccount.accountNo
      );
      if (applications) {
        setLettingAppInProgress(
          (!applications.find(
            (application: LettingApplicationFields) =>
              application.status === ApplicationStatus.submitted
          ) &&
            applications.filter(
              (application: LettingApplicationFields) =>
                application.status === ApplicationStatus.draft
            )[0]) ||
            undefined
        );
      } else {
        setLettingAppInProgress(undefined);
      }
    } else {
      setLettingAppInProgress(undefined);
    }
  };

  const fetchTargetedLettingCampaign = async () => {
    if (activeAccount) {
      try {
        const body = await fetchWrapper(
          withLettingsApiBaseUrl(
            `/targeted-campaign-candidate/${
              activeAccount.accountNo
            }?acceptedForLet=${false}`
          ),
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        setLettingAdvertisement(body);
      } catch (error: any) {
        // error while fetching targeted campaign
      }
    }
  };

  const updateTargetedLettingCampaignCandidate = async (
    interested: boolean
  ) => {
    try {
      await fetchWrapper(
        withLettingsApiBaseUrl(
          `/targeted-campaign-candidate/update-status/${activeAccount?.accountNo}`
        ),
        {
          method: 'PUT',
          credentials: 'include',
          body: JSON.stringify({
            interested,
          }),
        }
      );
      setLettingAdvertisement(null);
    } catch (error: any) {
      setLettingAdvertisement(null);
    }
  };

  const fetchLettingConfig = async () => {
    if (isUserLoggedIn) {
      try {
        const config = await fetchWrapper(
          withApiBaseUrl('/letting/letting-config?name=restrictedParks'),
          {
            method: 'GET',
            credentials: 'include',
          }
        );
        setLettingConfig(config);
      } catch (error) {
        // error while fetching letting config
      }
    }
  };

  const loginHandler = () => {
    window.location.assign(`${PUBLIC_LOGIN_URL}?redirect=${PUBLIC_APP_URL}`);
  };

  const ctaHandler = (url: string) => {
    if (url.startsWith('http')) {
      window.location.assign(url);
    } else if (url.startsWith('/')) {
      router.push(url);
    }
  };

  const handleLettingAdvertisementClick = (interested: boolean) => {
    updateTargetedLettingCampaignCandidate(interested);
    if (interested) router.push('/letting');
  };

  const onAdvertBannerLoad = () => {
    const bannerUrl =
      lettingAdvert?.id === 'auto'
        ? '/bookings?from=lettingAd'
        : `tel:${lettingAdvert?.contactDetails?.phone}`;
    setTimeout(() => {
      handleGTMBannerImpression('banner impression', {
        button_cta: lettingAdvert?.ctaText || '',
        image_url: 'noImage',
        link_url: bannerUrl,
        page_position: 'middle',
        title: lettingAdvert?.title || '',
        variant: 'letting-break-advert',
      });
    }, 1000);
  };

  const handleLettingAdvertBannerClick = () => {
    const bannerUrl =
      lettingAdvert?.id === 'auto'
        ? '/bookings?from=lettingAd'
        : `tel:${lettingAdvert?.contactDetails?.phone}`;

    handleGTMBannerImpression('banner click', {
      button_cta: lettingAdvert?.ctaText || '',
      image_url: 'noImage',
      link_url: bannerUrl,
      page_position: 'middle',
      title: lettingAdvert?.title || '',
      variant: 'letting-break-advert',
    });

    setTimeout(() => {
      router.push(bannerUrl);
    }, 500);
  };

  const restrictedParks = useMemo(
    () => parseRestrictedParks(lettingConfig),
    [lettingConfig]
  );

  const isLettingAvailable = useMemo(
    () =>
      activeAccount &&
      isParkValidForLettingMarketing(restrictedParks, activeAccount.parkCode),
    [activeAccount, restrictedParks]
  );

  return (
    <>
      {fullScreenManagedLettingAdvert?.isDisplayable &&
        showFullScreenManagedLettingAdvert && (
          <FullScreenManagedLettingAdvert
            advertData={fullScreenManagedLettingAdvert}
            handleClose={() => {
              mutate(fullScreenManagedLettingAdvertSWRKey);
              setShowFullScreenManagedLettingAdvert(false);
            }}
          />
        )}
      {lettingAdvertisement?.displayAd && (
        <LettingAdvertisementDialog
          lastYearOfLet={lettingAdvertisement.lastYearOfLet as number}
          userName={loggedInUser?.name}
          handleOk={() => handleLettingAdvertisementClick(true)}
          handleClose={() => handleLettingAdvertisementClick(false)}
        />
      )}
      {isUserLoggedIn && (
        <>
          <TooltipFactory
            tooltipType={TooltipType.NAVIGATION_YOUR_HOLIDAY_HOME}
            parentRef={yourHolidayHomeTooltipRef}
            placement={'bottom'}
          />
          {homepageWelcomeBanner && (
            <Banner
              fullWidthOnMobile
              typo="main"
              title="Owners' Hub"
              gutterBottom
              palette={
                homepageWelcomeBanner?.season?.[0] &&
                currentlySupportedHeroSeasons.includes(
                  homepageWelcomeBanner.season[0]
                )
                  ? homepageWelcomeBanner.season[0].toLowerCase()
                  : 'primary'
              }
              animation={
                <SeasonalBannerAnimations
                  animation={
                    homepageWelcomeBanner?.season?.[0] &&
                    currentlySupportedHeroSeasons.includes(
                      homepageWelcomeBanner.season[0]
                    )
                      ? homepageWelcomeBanner.season[0].toLowerCase()
                      : 'primary'
                  }
                />
              }
              content={
                <>
                  {loggedInUser?.name ? (
                    <p className="fs-mask">
                      Welcome back <b>{loggedInUser.name}</b>
                    </p>
                  ) : (
                    <p>Welcome back!</p>
                  )}
                  {activeAccount?.acceptedForLet === false &&
                  homepageWelcomeBanner?.lettingSubtext &&
                  isLettingAvailable ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homepageWelcomeBanner.lettingSubtext,
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homepageWelcomeBanner?.subtext || '',
                      }}
                    />
                  )}
                </>
              }
              {...(park &&
              park.countdownShowCountdown &&
              park.countdownDateTime &&
              new Date(park.countdownDateTime).getTime() >= new Date().getTime()
                ? {
                    extraContent: <ParkOpenCounter park={park} />,
                  }
                : {})}
              footer={
                <>
                  {homepageWelcomeBanner?.ctaName &&
                    homepageWelcomeBanner?.ctaUrl && (
                      <Button
                        size="large"
                        onClick={() =>
                          ctaHandler(homepageWelcomeBanner.ctaUrl || '')
                        }
                      >
                        {homepageWelcomeBanner.ctaName}
                      </Button>
                    )}
                </>
              }
            />
          )}

          {activeAccount?.accountID &&
            activeAccount?.pitchStatus &&
            lettingSummary &&
            !isLet2OwnLettingOwner && (
              <EarningPotential
                accountId={activeAccount.accountID}
                pitchStatus={activeAccount.pitchStatus}
              />
            )}

          {lettingAdvert && (
            <div className={styles.bannerContainer}>
              <AdvertisingBanner
                heading={lettingAdvert.heading}
                text={lettingAdvert.text}
                title={lettingAdvert.title}
                ctaText={lettingAdvert.ctaText}
                handleCtaClick={() => handleLettingAdvertBannerClick()}
                contactDetails={lettingAdvert.contactDetails}
                imageUrl={lettingAdvert.imageUrl}
                onLoad={onAdvertBannerLoad}
              />
            </div>
          )}
        </>
      )}

      {!isUserLoggedIn && (
        <Banner
          fullWidthOnMobile
          typo="main"
          title="Owners' Hub"
          content={
            <>
              <p>
                Sign in or register below to our brand new Owner Services
                website. By signing in, you’ll have access to a personalised
                dashboard with exclusive features and easy holiday home
                management.
              </p>
            </>
          }
          footer={
            <Button size="large" onClick={loginHandler}>
              Sign in / Register
            </Button>
          }
        />
      )}

      {lettingAppInProgress && (
        <div className={styles.lettingBanner}>
          <div className={styles.lettingBannerTitle}>You’re almost there</div>
          <div className={styles.lettingBannerBody}>
            <div className={styles.lettingBannerText}>
              Our letting service, <strong>Simply Letting</strong>, can help you
              sort it all out. And you’ll offset your holiday home’s running
              costs with the rental income.
            </div>
            <a
              className={styles.lettingBannerButton}
              href={`/lettings-hub/letting-application?type=${lettingAppInProgress.type.toLowerCase()}&id=${
                lettingAppInProgress.id
              }`}
            >
              <Button>Continue lettings application</Button>
            </a>
          </div>
        </div>
      )}

      <div>
        <OwnersGuideBanner />
      </div>

      <div className={styles.contentWrapper}>
        <div>
          {isUserLoggedIn && <UpComingEvents />}
          {isUserLoggedIn && (
            <div className={clsx(styles.gutterBottom, styles.gutterTop)}>
              <SectionHeader
                title="Entertainment"
                extraContent={
                  <a
                    className={styles.sectionHeaderLink}
                    href={PUBLIC_ENTERTAINMENT_APP_URL || ''}
                  >
                    See Entertainment Schedule
                  </a>
                }
              />
            </div>
          )}

          {activeAccount && (
            <div className={styles.gutterBottom}>
              <SectionHeader
                title="Latest news"
                extraContent={
                  <Link href={'/news-and-information'}>
                    <span className={styles.sectionHeaderLink}>
                      See all News & information
                    </span>
                  </Link>
                }
              />
              <ImportantReads parkCode={activeAccount.parkCode} onHub={true} />
            </div>
          )}
          <SectionHeader title="Your holiday home" />
          <div className={styles.cardsContainer}>
            {!lettingSummary?.let2Own && (
              <Link href="/finance" legacyBehavior>
                <a data-testid="finance-card">
                  <Card
                    variant="alternate"
                    titleIsLink
                    borderRadius
                    icon="/assets/hub/finance.svg"
                    title="Finance"
                    description="Your annual charges are ready to view here"
                  />
                </a>
              </Link>
            )}
            {(!activeAccount?.parkCode || isLettingAvailable) && (
              <a data-testid="letting-card" href="/lettings-hub">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/lettings.svg"
                  title="Lettings Hub"
                  description="Sign up and Manage your Holiday Home"
                  headerContent={
                    lettingActionRequired ? (
                      <div
                        className={clsx(
                          styles.tileHeader,
                          styles.lettingAction
                        )}
                      >
                        ACTION REQUIRED
                        <img src="/assets/notification.svg" alt="" />
                      </div>
                    ) : (
                      <>
                        {lettingAppInProgress && (
                          <div className={styles.badge}>
                            APPLICATION IN PROGRESS
                          </div>
                        )}
                      </>
                    )
                  }
                />
              </a>
            )}
            <Link href="/part-exchange" legacyBehavior>
              <a data-testid="part-exchange-card">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/partExchange.svg"
                  title="Part Exchange"
                  description="Looking for a new holiday home to start the new season?"
                />
              </a>
            </Link>
            <Link href="/drain-down" legacyBehavior>
              <a data-testid="drain-down-card">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/drainDown.svg"
                  title="Book a Drain Down"
                  description={
                    currentDrainDownBookings?.drainDown &&
                    currentDrainDownBookings?.reconnection
                      ? `Booked for ${fromatBookedDates(
                          currentDrainDownBookings.drainDown
                        )}
                    with reconnection ${fromatBookedDates(
                      currentDrainDownBookings.reconnection
                    )}. To change your booking please call ${
                          park?.phoneNumber
                        }. `
                      : "With winter coming, it's important to book a Drain Down to protect your holiday home from the elements"
                  }
                  /* headerContent={
                    <>
                      {activeAccount?.parkCode &&
                        isParkValidForFrostWarning(activeAccount.parkCode) && (
                          <div
                            className={clsx(styles.tileHeader, styles.frostDue)}
                          >
                            FROST DUE
                            <img src="/assets/frost.svg" alt="" />
                          </div>
                        )}
                    </>
                  } */
                />
              </a>
            </Link>
          </div>
          <SectionHeader title="On Park" />
          <div className={styles.cardsContainer}>
            <Link href="/bookings" legacyBehavior>
              <a data-testid="arrival-card">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/arrivals.svg"
                  title="Register an arrival"
                  description="Are you planning a visit to Haven soon? Please register your arrival here"
                />
              </a>
            </Link>
            <Link href="/bookings?view=list" legacyBehavior>
              <a data-testid="bookings-card">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/checkIn.svg"
                  title="Get car pass"
                  description="For a more seamless arrival, make sure you get your car pass in advance"
                />
              </a>
            </Link>
            <a
              href={`${PUBLIC_ACTIVITIES_APP_URL}/interstitial`}
              data-testid="activities-card"
            >
              <Card
                variant="alternate"
                titleIsLink
                borderRadius
                icon="/assets/hub/activities.svg"
                title="Book Activities &amp; Events"
                description="Check out what's on for your park"
              />
            </a>
            {/*
              <a data-testid="table-booking-card" href="/food">
                <Card
                  variant="alternate"
                  titleIsLink
                  borderRadius
                  icon="/assets/hub/food.svg"
                  title="Food &amp; Drink"
                  description="Book a table or order a takeaway for collection or delivery (where available)"
                />
              </a>
            */}

            {/*<Link href="/guides/park-opening">
              <a>
                <Card
                  icon="/assets/guide.jpg"
                  title="Welcome back guides"
                  description="We’re delighted to be getting ready to open park and welcoming everyone back. Please read our guides to ensure we all stay safe"
                />
              </a>
            </Link>*/}
          </div>
        </div>

        {loggedInUser?.userType === 'USER' && !!topPrioBanners.length && (
          <div className={styles.bannerContainer}>
            <BannerList banners={topPrioBanners} />
          </div>
        )}
        {loggedInUser?.userType === 'USER' && !!normalBanners.length && (
          <div className={styles.bannerContainer}>
            <BannerList banners={normalBanners} />
          </div>
        )}
      </div>
    </>
  );
};

export const getStaticProps = () => ({
  props: {
    pageTitle: 'Welcome',
    availableForGuests: true,
  },
  revalidate: 60 * 30,
});

export default HomePage;
